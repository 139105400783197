// this is the place for your components / CSS
.slick-prev {
  left: 0.75rem!important;
  z-index:1000;
}
.slick-next {
  right: 1.25rem!important;
  z-index:1000;
}

.slick-dots {
  bottom: 0.5rem!important;
}

.slick-dots li button:before {
  color: white!important;
  font-size: 0.5rem!important;
  opacity: 0.7!important;
}

.slick-dots li.slick-active button:before {
  font-size: 0.6rem!important;
  opacity: 1!important;
}

.slick-prev:before, .slick-next:before {
  font-size: 1.7rem!important;
  text-shadow: 1px 1px #222!important;
}

.modal-content-full-width  {
  height: auto !important;
  min-height: 100% !important;
  border-radius: 0 !important;
}

.modal-header-full-width  {
  border-bottom: 1px solid #9ea2a2 !important;
}

.modal-footer-full-width  {
  border-top: 1px solid #9ea2a2 !important;
}


.icon-flag-england {
  background-image : url(https://upload.wikimedia.org/wikipedia/commons/thumb/b/be/Flag_of_England.svg/200px-Flag_of_England.svg.png);
  background-size: cover;
  display: inline-block;
  height: 18px;
  width: 30px;
  margin-right: 0.7rem;
}

.icon-flag-scotland {
  background-image : url(https://upload.wikimedia.org/wikipedia/commons/thumb/0/05/Flag_of_Scotland_%281542-2003%29.svg/200px-Flag_of_Scotland_%281542-2003%29.svg.png);
  background-size: cover;
  display: inline-block;
  height: 18px;
  width: 30px;
  margin-right: 0.7rem;
}

.icon-flag-wales {
  background-image : url(https://upload.wikimedia.org/wikipedia/commons/thumb/a/a9/Flag_of_Wales_%281959%E2%80%93present%29.svg/200px-Flag_of_Wales_%281959%E2%80%93present%29.svg.png);
  background-size: cover;
  display: inline-block;
  height: 18px;
  width: 30px;
  margin-right: 0.7rem;
}

.icon-flag-ireland {
  background-image : url(https://upload.wikimedia.org/wikipedia/commons/thumb/4/45/Flag_of_Ireland.svg/200px-Flag_of_Ireland.svg.png);
  background-size: cover;
  display: inline-block;
  height: 18px;
  width: 36px;
  margin-right: 0.7rem;
}

.icon-flag-uk {
  background-image : url(https://upload.wikimedia.org/wikipedia/commons/thumb/a/ae/Flag_of_the_United_Kingdom.svg/200px-Flag_of_the_United_Kingdom.svg.png);
  background-size: cover;
  display: inline-block;
  height: 18px;
  width: 36px;
  margin-right: 0.7rem;
}

.cottage-search-selection .btn {
  letter-spacing: 0.05rem!important;
}

.search-bar {
  border-radius: 1rem!important;
  margin-bottom: 1rem;
}

.search-form-control {
  padding: 0.175rem 0.75rem!important;
}

.date-range-picker table {
  font-weight: 600!important;
  border-collapse: unset!important;
  //border-spacing: 2px!important;
}

.date-range-picker td {
  border: 0px!important;
  border-color: #fff!important;
  border-radius: 2rem;
}

.search-menu-item {
  font-size: 1rem!important;
  font-weight: 400!important;
  letter-spacing: 0!important;
  color: #333!important;
  width: 100%;
  text-align: left;
  padding-top: 0.6rem;
}

.search-menu-item-placeholder {
  color: #808080!important;
}

.search-menu-item:hover {
  text-decoration: none!important;
}

.btn {
  text-transform: unset !important;
}

.red {
  color: red!important;
}

.btn-light {
  color: #222;
  background-color: #fff;
  border-color: #ccc;
}

//.text-muted {
//  color: #212529 !important;
//}

.modal-dialog-full-width {
  width: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
  max-width:none !important;

}

.modal-content-full-width  {
  height: auto !important;
  min-height: 100% !important;
  border-radius: 0 !important;
}

.modal-header-full-width  {
  border-bottom: 1px solid #9ea2a2 !important;
}

.modal-footer-full-width  {
  border-top: 1px solid #9ea2a2 !important;
}

.result-item {
  cursor: pointer;
}

.badge-light {
  background-color: #eeeeee;
}

.swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%234c71ae'%2F%3E%3C%2Fsvg%3E") !important;
}

.last-viewed-next {
  right: 20px!important;
}

.last-viewed-prev {
  left: 20px!important;
}

.swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%234c71ae'%2F%3E%3C%2Fsvg%3E") !important;
}

.swiper-container {
  cursor: pointer;
}

.book-button {
  letter-spacing: 0.1em!important;
}

.datepicker-form-group {
  padding: 2rem 1rem 0.5rem 1rem;
}

.travel-plan-info-icon {
  font-size: 1.2rem;
}

.no-charges-text {
  font-size: 0.8rem;
  margin-top: 0.5rem;
}

.inclusive {
  margin-left: 0.4rem;
}

.form-label-nights {
  font-weight: 600;
  color: #484848;
}

.date-legenda {
  margin-top: 1rem;
  margin-right: 1rem;
  font-size: 2rem;
  color: #ffe8bc;
}

.request-price-button {
  margin-top: 1rem;
}

.page-link:hover {
  cursor: pointer;
}

.back-to-search-button {
  margin: 1rem 1rem;
  left: unset!important;
}

.search-item-badge {
  background-color: #fff;
}

.current-search {
  font-size: 0.9rem;
}

.search-item-badge a {
  color: #000;
  font-size: 0.7rem;
}

.fluid-margin {
  padding-left: 2rem;
  padding-right: 2rem;
}

.cottage-highlights-tab > .btn-link {
  color: #999;
  font-size: 1.4rem;
  text-decoration: none;
  margin-bottom: 1rem;
  letter-spacing: unset;
  margin-right: 1rem;
}

.cottage-highlights-tab > .active{
  color: black!important;
  border-bottom: 3px solid black;
}

.inspirational-item {
  text-align: center;
}

.py-3 {
  padding-top: 3rem!important;
  padding-bottom: 3rem!important;
}

.first-line {
  font-weight: 600;
}

.blockquote {
  font-size: 1.1rem;
}

h1 {
  font-size: 1.8rem;
}

h2 {
  font-size: 1.4rem;
}

.btn-narrow {
  letter-spacing: unset;
  padding: 0.25rem 0rem;
}

.btn {
  letter-spacing: 0.05rem;
}

a.anchor {
  display: block;
  position: relative;
  top: -100px;
  visibility: hidden;
}

.booking-label {
  font-weight: 700;
}

.sub-label {
  font-size: 0.8rem;
  color: #999;
  font-weight: 500;
  display: block;
}

.booking-column {
  font-size: 0.9rem;
}

.price-includes-info-block {
  display: flex;
  justify-content: space-between;
}

.text-shadow {
  z-index: 999;
}

.block-title {
  z-index: 1;
}

.navbar a {
  color: #000000!important;
}

.tile-link {
  display: flex;
  text-align: center;
  align-content: center;
  align-items: center;
  justify-content: center;
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 1.3;
  padding: 2rem;
  color: #FFFFFF;
}

.tile-link:hover {
  text-decoration: none;
  color: #FFFFFF;
}

.block-item {
  min-height: 20rem!important;
}

.large-tab-icon {
  font-size: 1.43rem!important;
}

.homepage-header-h1 {
  font-size: 1.5rem;
}

.subtitle-small {
  font-size: 0.7rem;
}

.dark-overlay-gradient::after {
  background: none!important;
  background-image: linear-gradient(black, rgba(255,0,0,0), rgba(255,0,0,0))!important;
}

.text-black {
  color: black;
}

.text-green-light {
  color: #8eec55;
}

.card-transparent {
  opacity: 0.95;
}

.align-items-left {
  text-align: left;
}

.selectpicker__placeholder {
  color: hsl(0%, 0%, 30%)!important;
}

.navbar .btn {
  color: #FFFFFF!important;
}

.search-form-label {
  color: #333!important;
  font-weight: 600;
}

.btn-group-main-search {
  width: 100%;
}

.btn-main-search {
  width: 100%;
  text-align: left;
  color: #4d4d4d!important;
  font-weight: 400;
}

.btn-main-search:after {
  display: none;
}

.home-search-input-label {
  color: #333;
  font-weight: 600;
  font-size: 0.9rem;
  margin: 0.2rem;
}

.main-search-input {
  min-width: 32rem;
}

.home-search-dropdown {
  min-width: 27rem;
}

.dark {
  color: #212529!important;
}

.py-12 {
  padding-bottom: 12rem;
  padding-top: 12rem;
}

.delete-button {
  position: absolute;
  right: 1rem;
  top: 0.5rem;
  z-index: 999999;
}

.nav-link {
  cursor: pointer;
}

.search-button-text {
  overflow: hidden;
  width: 90%;
  text-overflow: ellipsis;
  display: block;
}

.mobile-search-button {
  padding: 0 1rem
}

.mobile-search-icon {
  font-size: 1.2rem;
}


// Beginning of mobile menu styling
.mobile-menu {
  padding: 0 2rem;
  position:fixed;
  z-index: 999;
  bottom: 0;
  width: 100%;
  height: 60px;
  background-color: #fff;
  border-top: 1px solid #d0d0d0;
  -webkit-transform: translate3d(0,0,0);
  //box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
}

.mobile-menu-cottage {
  height: 4rem;
}

.mobile-search-menu {
  position:fixed;
  z-index: 99999999;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  border-top: 1px solid #d0d0d0;
  padding-bottom: 1rem;
}

.selectors, .block{
  height:100%;
  width:100%;
}

.selectors button{
  border: 0;
  border-radius: 0;
  background-color: #fff !important;
  width:25%;
  margin-left: 0;
}

.selectors button:active{
  border:0;
}

.selectors button:focus{
  border:0;
  outline: 0;
  box-shadow: 0 0 0 0px;
}

.selector-holder{
  display: flex;
  flex-direction: column;
}

.selector-holder span{
  font-size: 0.6rem;
}

/* Colors of the buttons*/
.selectors button:hover, .selectors button:active, .selectors button:focus{
  color: #000;
  background-color: #f1f1f1!important;
}

.button-active {
  color: #008000!important;
  border-top: 0.2rem solid #008000!important;
}

.button-inactive{
  color: #000;
}
// End of mobile menu styling

.full-screen-modal {
  margin: 0;
}

.full-screen-modal > .modal-content {
  background-clip: unset;
  border-radius: 0;
  padding-bottom: 3rem;
  margin: 0px!important;
  border: 0px;
}

.mobile-search-menu .selector-holder span{
  font-size: 0.65rem!important;
}

.mobile-search-menu .search-menu-button-search {
  color: #fff!important;
  background-color: #008000!important;
  margin: 0 0.3rem 0.3rem 0.3rem;
}

.modal-backdrop {
  background-color: #fff;
  width: 100%;
}

.modal-backdrop.show {
  opacity: unset;
}

.full-screen-modal .modal-body {
  padding: 0 0 5rem 0;
  height: 100%;
}

.full-screen-modal {
  height: 100%;
}

.modal-content {
  height: 100%;
}

#react-select-countryPicker-input {
  font-size: 16px!important;
}

#react-select-regionPicker-input {
  font-size: 16px!important;
}

#react-select-areaPicker-input {
  font-size: 16px!important;
}

#react-select-pickupLocationPicker-input {
  font-size: 16px!important;
}

#react-select-dropOffLocationPicker-input {
  font-size: 16px!important;
}

.small-text-modal-header {
  font-size: 0.8rem;
}

.underline {
  text-decoration: underline;
}

.modal-body {
  //max-height: calc(100vh - 210px);
  overflow-y: auto;
}

.selectpicker__menu {
  z-index: 99999999999 !important;
}

.mobile-selection-summary {
  font-weight: 500;
  padding: 0 1.5rem;
  font-size: 0.75rem;
}

.modal-dialog-full-width .form-label {
  font-size: 0.875rem;
  font-weight: 600;
  padding: 0.7rem;
  color: #212529!important;
}

.modal-dialog-full-width .dropdown-header {
  color: #212529!important;
}

.notification-badge {
  height: 1.2rem;
  width: 1.2rem;
  position: absolute;
  font-size: 0.8rem!important;
  top: 0.1rem;
  color: #fff;
  right: 23%;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
}

.notification-badge-desktop {
  height: 1.2rem;
  width: 1.2rem;
  position: absolute;
  font-size: 0.8rem!important;
  top: -0.8rem;
  color: #fff;
  right: -0.8rem;
  background-color: green;
  //background-color: #4E66F8;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
}

.swiper-container {
  z-index: 39;
  //height: 16rem;
}

.swiper-container-cottage-card > .swiper-container {
  height: 16rem;
}

.swiper-pagination {
  color: white;
  font-weight: 600;
}

.favourite {
  opacity: 0.9;
  background-color: #FFFFFF;
}

.favourite:hover {
  opacity: 1;
  //background-color: #ff6363;
  cursor: pointer;
}

.selected-favourite {
  opacity: 1;
  background-color: #ff6363;
  cursor: pointer;
}

.text-gray {
  color: #6c757d!important;
  font-weight: 600;
  font-size: 0.8rem;
}

.favourite-item {
  position: relative;
}

.mobile-cottage-search-menu {
  width: 80%;
}

.mobile-cottage-search-menu .btn {
  color: black!important;
  font-weight: 400;
}

.mobile-cottage-search-menu .bordered{
  border-right: 1px solid black!important;
}

.mobile-cottage-search-menu-icon {
  margin-right: 0.2rem;
}

.back-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-nights-select {
  font-size: 16px!important;
}

.popular-areas {
  padding: 0.7rem 1.5rem;
}

.cottage-card-on-map {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 99999;
  max-width: 13rem;
  margin: 4rem 1rem;
}

//.full-screen-modal > .modal-content {
//  height: 100%;
//  border-radius: 0;
//  position:relative;
//}
//
//.full-screen-modal > .modal-footer {
//  border-radius: 0;
//  bottom:0px;
//  position:absolute;
//  width:100%;
//}

html,
body {
  //overflow-x: hidden; /* Prevent scroll on narrow devices */
}

body {
  padding-top: 56px;
}

@media (max-width: 991.98px) {
  .offcanvas-collapse {
    position: fixed;
    top: 60px; /* Height of navbar */
    bottom: 0;
    left: 100%;
    width: 100%;
    padding-right: 1rem;
    padding-left: 1rem;
    overflow-y: auto;
    visibility: hidden;
    //background-color: #343a40;
    //transition-timing-function: ease-in-out!important;
    //transition-duration: .3s!important;
    //transition-property: left, visibility!important;
    background-color: white;
    transition: visibility .2s ease-in-out, -webkit-transform .2s ease-in-out;
    height: 100%!important;

  }
  .offcanvas-collapse.show {
    visibility: visible;
    transform: translateX(-100%);
    padding-bottom: 5rem;
  }
}

//.nav-scroller {
//  position: relative;
//  z-index: 2;
//  height: 2.75rem;
//  overflow-y: hidden;
//}
//
//.nav-scroller .nav {
//  display: -ms-flexbox;
//  display: flex;
//  -ms-flex-wrap: nowrap;
//  flex-wrap: nowrap;
//  padding-bottom: 1rem;
//  margin-top: -1px;
//  overflow-x: auto;
//  color: rgba(255, 255, 255, .75);
//  text-align: center;
//  white-space: nowrap;
//  -webkit-overflow-scrolling: touch;
//}
//
//.nav-underline .nav-link {
//  padding-top: .75rem;
//  padding-bottom: .75rem;
//  font-size: .875rem;
//  color: #6c757d;
//}
//
//.nav-underline .nav-link:hover {
//  color: #007bff;
//}
//
//.nav-underline .active {
//  font-weight: 500;
//  color: #343a40;
//}
//
//.text-white-50 { color: rgba(255, 255, 255, .5); }
//
//.bg-purple { background-color: #6f42c1; }
//
//.lh-100 { line-height: 1; }
//.lh-125 { line-height: 1.25; }
//.lh-150 { line-height: 1.5; }

.text-primary a {
  color: #008000!important;
}

.mobile-usp {
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.2!important;
  display: block;
}

.overflow-none {
  overflow: unset;
}

.camper-gallery {
  height: 400px;
}

.mobile-camper-date-range-picker {
  display: flex;
  justify-content: center;
}

.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 120px;
  height: 120px;
  margin: -76px 0 0 -76px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.loader-small {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 1.2rem;
  height: 1.2rem;
  animation: spin 2s linear infinite;
  margin-left: 1rem;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.camper-card {
  cursor: pointer;
}

.navbar .btn {
  color: #4d4d4d!important;
}

.search-button-icon {
  font-size: 1.2rem;
}

.search-button-icon-col {
  display: flex;
  align-items: center;
}

.search-button-label {
  font-size: 0.7rem;
  font-weight: bold;
}

.delete-button-search-page {
  position: absolute;
  right: 1rem;
  top: 1rem;
  z-index: 9;
}

.map-side-lg {
  top: 167px!important;
}

.map-side-lg .map-full{
  height: calc(100vh - 167px)!important;
}

.top-50 {
  top: 50px!important;
}

.min-width-800 {
  width: 800px;
}

.min-width-450 {
  width: 450px;
}

.cottage-search-form-group {
  margin-bottom: 0.6rem;
}

.megamenu-logos {
  padding-top: 0rem!important;
  padding-bottom: 0rem!important;
}

.scrollable-dropdown {
  overflow-y: auto;
  max-height: 600px;
}

.rbt-menu {
  top: unset!important;
}

.rbt-input::placeholder {
  font-size: 0.9rem!important;
}

.label-absolute {
  z-index: 99;
}

.rbt-menu > span > .dropdown-header {
  text-transform: unset;
  padding: 0.5rem 1.5rem;
}

.input-expand .form-control {
  max-width: 25rem!important;
}

.checkout-camper-price {
  display: flex;
  flex-direction: row-reverse;
}

.negative-padding-top {
  margin-top: -56px!important;
}

.rbt-close {
  margin-top: 0px!important;
}

.dropdown-item.active {
  background-color: #eee;
}

.megamenu-list-link.dropdown-item {
  white-space: normal;
}

.mobile-main-search .rbt-menu {
  max-height: unset!important;
  margin-top: -10px!important;
}

.rbt-input-main {
  font-size: 16px!important;
}

div.fadeMe {
  opacity:    0.5;
  background: #000;
  width:      100%;
  height:     100%;
  z-index:    10;
  top:        0;
  left:       0;
  position:   fixed;
  z-index: 99;
}

.arrival-date-legenda {
  font-size: 0.8rem;
}

.desktop-backdrop {
  opacity: 0.5!important;
  background-color: #000;
}

.modal-border {
  border: 1px solid #eee;
}

.modal-content-mobile {
  font-size: 0.9rem;
}

.map-side-mobile {
  top: 0px!important;
}

.text-xsm {
  font-size: 0.8rem;
}

.navigation-loader {
  position: fixed; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8); /* Black background with opacity */
  z-index: 2000000000; /* Specify a stack order in case you're using a different order for other elements */
}

.z-index-200 {
  z-index: 200!important;
}

.back-button-arrow {
  padding: 1rem 1rem 1rem 0;
}

.card-fav-icon i {
  vertical-align: middle;
  stroke-width: 5;
  font-size: 1.2rem;
}

.text-gray-dark {
  color: #4d4d4d;
}

.modal-body-no-padding {
  padding: 0rem;
}

.no-margin {
  margin: 0rem;
}

.selected-search-text {
  font-weight: 600;
  color: #222222;
}

.selected-search-text .search-button-label {
  color: #4d4d4d;
}

.modal-close-button {
  font-size: 1.2rem;
  color: #8c8b8b;
  cursor: pointer;
  padding: 1rem 1rem 1rem 0;
}

.cottagecentre-modal-header {
  display: inline;
  font-size: 0.8rem;
  font-weight: bold;
  align-items: center;
  padding: 1.2rem 1.2rem 0.4rem;
}

.selection-delete-button {
  color: green;
  text-decoration: underline;
}

.selected-filter-indicator {
  position: absolute;
  left: 3.7rem;
  top: 0.1rem;
  color: green;
  font-size: 1rem!important;
}

.ribbon-orange {
  background-color: #fe6400;
}

.ribbon-dark-gray {
  background-color: #8c8b8b;
}

.font-size-1 {
  font-size: 1rem;
}

.background-light-grey {
  background-color: #e9ecef;
}

.background-orange {
  background-color: orange;
}

.w-70 {
  width: 70%!important;
}

.w-60 {
  width: 60%!important;
}

.w-30 {
  width: 30%!important;
}

.w-20 {
  width: 20%!important;
}

.w-15 {
  width: 15%!important;
}

.w-10 {
  width: 15%!important;
}

.vertical-align-center {
  vertical-align: middle!important;
}

.selected-amount-button {
  color: #fff!important;
  border: solid 2px #fff!important;
  font-weight: bold!important;
}

.text-align-end {
  text-align: end;
}

.display-no-block {
  display: inline;
  width: auto;
}

.flexibility-picker {
  padding-left: 2.5rem;
  //padding-top: 1rem;
  padding-bottom: 1rem;
}

.flexibility-picker > .custom-control > .custom-control-label {
  padding-top: 0.2rem;
}

.date-range-picker-height-100 {
  height: 90%;
}

.DayPicker__verticalScrollable {
  margin-top: -4px;
}

.text-align-center {
  text-align: center;
}

.btn.disabled {
  opacity: 0.55;
}

.action-text {
  font-style: italic;
}

.action-content {
  font-size: 1.2rem;
}

.text-orange {
  color: #fe6400;
}

.map-loading {
  text-align: center;
  padding-top: 150px;
}

.map-loading-icon {
  font-size: 4rem!important;
}
