/*
* ===================================================
*    Other
* ===================================================
*/

.img-gallery {
    &:hover {
        cursor: pointer;
    }
}