/*
* ===================================================
*    Modal
* ===================================================
*/
.ReactModal__Body--open {
    overflow: hidden;

    #__next {
        position: relative;
        z-index: 0;
    }

    .ril__toolbar {
        background: transparent;
    }

    @include media-breakpoint-up(xl) {
        padding-right: 16px;

        #__next {
            header .navbar {
                right: 16px;
            }
        }
    }
}